import { Box, Typography } from "components"
import { isValid, parse, parseISO } from "date-fns"
import { useMemo } from "react"
import { formatDate } from "utils/helper"
import { BasePreviewProps } from "."

export const DateTimePreviewField = (props: BasePreviewProps) => {
  const { label, value } = props

  const displayValue = useMemo(() => {
    if (value === undefined || value === null) return "-"

    if (typeof value === "string") {
      let parsedDate
      if (isValid(parseISO(value))) {
        parsedDate = parseISO(value)
      } else {
        parsedDate = parse(value, "dd/MM/yyyy", new Date())
      }

      if (isValid(parsedDate)) {
        return formatDate(parsedDate, "dd/MM/yyyy")
      } else {
        return value
      }
    }
    return value
  }, [value])

  return (
    <>
      <Typography variant="subtitle2" color="Text/Gray Preview">
        {label}
      </Typography>
      <Box mt="4px">
        <Typography variant="h4" color="Text/Black2">
          {displayValue}
        </Typography>
      </Box>
    </>
  )
}
