import { Box, Grid, Typography } from "components"
import { AppColor } from "theme/app-color"
import styled from "styled-components"
import { toCurrency } from "utils/helper"
import { Label } from "../claim-detail/form-field/Label"
import { isEmpty } from "lodash"
import { withHooks, compose } from "enhancers"

const DetailContainer = styled(Box)``

const BudgetCard = styled(Box)`
  padding: 8px;
  border: 1px solid ${AppColor["Gray/Flat Button Bg"]};
  border-radius: 4px;
  background-color: ${AppColor["White / White"]};
`

const LimitDataComponent = (props: any) => {
  const { limitBudget } = props
  return (
    <Typography variant="body1" color="Text/Gray Success" style={{ lineHeight: "24px", marginLeft: "4px" }}>
      /
      {!isNaN(limitBudget)
        ? toCurrency(limitBudget, {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          })
        : "-"}
    </Typography>
  )
}

const RemainDataComponent = (props: any) => {
  const { remainingBudget } = props
  const budgetValue = remainingBudget <= 0 ? 0 : remainingBudget
  return (
    <Typography variant="h3" color="Primary/Primary Text">
      {!isNaN(remainingBudget)
        ? toCurrency(budgetValue, {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          })
        : "-"}
    </Typography>
  )
}

const BudgetComponent = (props: any) => (
  <>
    {!isEmpty(props.budgetDetail) && props.budgetValues && (
      <Grid container spacing={2} mb={1} mt={8}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <Label title={"วงเงินคงเหลือ"} icon="payments" iconColor={AppColor["Primary/Primary Text"]} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <BudgetCard>
            <Box display="flex" flexDirection="column" padding="8px">
              <Box display="flex" justifyContent="space-between">
                <Typography variant="body1" color="Text/Gray Preview">
                  วงเงินคงเหลือปี
                </Typography>
                <Typography variant="h3" color="Primary/Primary Text">
                  {props.getYear}
                </Typography>
              </Box>
              {props.budgetDetail && (
                <DetailContainer>
                  {props.budgetDetail.map((detail: any) => {
                    return (
                      <Box display="flex" pt={2} justifyContent="space-between" key={`${detail.label}-3`}>
                        <Typography variant="body1" color="Text/Gray Preview">
                          {detail.label}
                        </Typography>
                        <Box display="flex">
                          {detail.field ? (
                            <>
                              <RemainDataComponent remainingBudget={props.remainingBudget} />
                              <LimitDataComponent limitBudget={props.limitBudget} />
                            </>
                          ) : (
                            <Typography variant="h3" color="Primary/Primary Text">
                              {detail.value ? detail.value : "N/A"}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    )
                  })}
                </DetailContainer>
              )}
            </Box>
          </BudgetCard>
        </Grid>
      </Grid>
    )}
  </>
)
const enhancer = compose(
  withHooks((props: any, hooks: any) => {
    const { useMemo } = hooks
    const { budgetDetail, budgetValues, year, values, yearlyBudget, type } = props
    const { remainingBudget, limitBudget } = useMemo(() => {
      let remainingBudget = undefined
      let limitBudget = undefined
      if (isEmpty(budgetDetail)) {
        return { remainingBudget, limitBudget }
      }
      if (Object.keys(yearlyBudget || {}).length > 0) {
        remainingBudget = type === "dental_fee" ? yearlyBudget.dentalBudget : yearlyBudget.budget
        limitBudget = type === "dental_fee" ? yearlyBudget.companyDentalBudget : yearlyBudget.companyBudget
      } else {
        const haveRemoteBudget = budgetDetail.find((detail: any) => detail.remoteBudget) !== undefined
        if (haveRemoteBudget && budgetValues) {
          remainingBudget = budgetValues?.remoteBudget?.remainingBudget
          limitBudget = budgetValues?.remoteBudget?.maximumBudget
        } else {
          budgetDetail.forEach((detail: any) => {
            if (
              detail.field &&
              detail.maximumFiled &&
              values[detail.maximumFiled] &&
              budgetValues.detail[detail.field]
            ) {
              const maxField = values[detail.maximumFiled]
              const budgetField = budgetValues.detail[detail.field]
              remainingBudget = maxField - budgetField
              limitBudget = maxField
            }
          })
        }
      }
      return { remainingBudget, limitBudget }
    }, [yearlyBudget, type, budgetDetail, values, budgetValues])

    const getYear = useMemo(() => {
      return year ? year : "-"
    }, [year])

    return {
      budgetDetail,
      budgetValues,
      year,
      values,
      remainingBudget,
      limitBudget,
      getYear,
    }
  }),
)

export default enhancer(BudgetComponent)
